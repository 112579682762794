import React, { Component, useState, useEffect } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// Component
import HeadingBanner from "./HeadingBanner";
// Use the CDN-hosted worker
import { pdfjs, Document, Page } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@4.8.69/build/pdf.worker.min.mjs`;

const options = {
  cMapUrl: "/cmaps/",
  standardFontDataUrl: "/standard_fonts/",
};

const Catalogue = () => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageWidth, setPageWidth] = useState(window.innerWidth * 0.8);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  useEffect(() => {
    const updateWidth = () => {
      setPageWidth(
        window.innerWidth < 768 ? window.innerWidth : window.innerWidth * 0.5
      );
    };

    window.addEventListener("resize", updateWidth);
    updateWidth(); // Initial check

    return () => window.removeEventListener("resize", updateWidth);
  }, []);
  function goToPrevPage() {
    setPageNumber((prevPage) => Math.max(prevPage - 1, 1));
  }

  function goToNextPage() {
    setPageNumber((prevPage) => Math.min(prevPage + 1, numPages));
  }

  return (
    <div
      style={{
        position: "relative", // Enable absolute positioning for child elements
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <HeadingBanner label={"The Colorist Catalogue"} />
      <nav
        style={{
          width: "100%",
          textAlign: "center",
          padding: "10px",
        }}
      >
        <p style={{ margin: "10px 0" }}>
          Page {pageNumber} of {numPages}
        </p>
      </nav>

      {/* Prev Button */}
      <button
        onClick={goToPrevPage}
        disabled={pageNumber === 1}
        className="catalogue-button"
        style={{
          left: "10px", // Position the button on the right side
        }}
      >
        <ArrowBackIcon />
      </button>

      {/* PDF Viewer */}
      <Document
        file="/Catalogue.pdf"
        onLoadSuccess={onDocumentLoadSuccess}
        options={options}
        onLoadError={(error) => console.error("PDF loading error:", error)}
      >
        <Page
          pageNumber={pageNumber}
          renderTextLayer={false}
          renderAnnotationLayer={false}
          width={pageWidth}
        />
      </Document>

      {/* Next Button */}
      <button
        onClick={goToNextPage}
        disabled={pageNumber === numPages}
        className="catalogue-button"
        style={{
          right: "10px", // Position the button on the right side
        }}
      >
        <ArrowForwardIcon />
      </button>
    </div>
  );
};

export default Catalogue;
